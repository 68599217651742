import { cloneElement, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, BoxProps, Circle, Flex, Text } from '@chakra-ui/react';
import { match } from 'ts-pattern';

import {
  AppContainer,
  Icon,
  IconProps,
  TabsContent,
  TabsList,
  useGradient,
  useTabsContext,
} from '@arena-labs/strive2-ui';
import { $API, useUserProfile } from '@strive/api';
import {
  IfWearable,
  StrivewareContext,
  StrivewareSelector,
} from '@strive/wearable';

import { LaunchpadFooter } from '../launchpad/launchpad-footer';
import { AppFooter } from './app-footer';

export type AppLayoutProps = {
  children: React.ReactNode;
  fixedHeight?: boolean;
  footer?: React.ReactElement | false;
  contentProps?: BoxProps;
} & (
  | {
      pageTitle: React.ReactNode;
      pageIcon?: IconProps['as'];
      bannerExtraRight?: React.ReactNode;
      bannerExtraBottom?: React.ReactNode;
    }
  | {
      banner: React.ReactNode;
    }
);

export function AppLayout({
  fixedHeight,
  children,
  footer,
  ...props
}: AppLayoutProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const sentinelRef = useRef<HTMLDivElement>(null);
  const bgGradient = useGradient('background');
  const route = useRouter();
  const { tabs } = useTabsContext();
  const { data: user } = useUserProfile();
  const learningState = $API.useGetHomepage(
    {},
    { select: (data) => data.learning_state },
  );

  if (user && footer === undefined) {
    footer = match(learningState.data)
      .with('launchpad', () => <LaunchpadFooter />)
      .otherwise(() => <AppFooter />);
  }

  const childContent = tabs?.length ? (
    <TabsContent>{children}</TabsContent>
  ) : (
    children
  );

  const bannerContent =
    'pageTitle' in props ? (
      <>
        <Flex gap="3" py="4" pl={4} pr={2} color="neutral.800" align="center">
          {props.pageIcon && (
            <Circle bg={'neutral.100'} size={'32px'}>
              <Icon as={props.pageIcon} boxSize="6" />
            </Circle>
          )}
          <Text textStyle={'h4'} mr={'auto'} textShadow="8dp">
            {props.pageTitle}
          </Text>
          {props.bannerExtraRight}
        </Flex>
        {props.bannerExtraBottom}
      </>
    ) : (
      props.banner
    );

  const footerProps = {
    gridArea: 'footer',
    tabs: tabs?.length ? <TabsList /> : null,
    sticky: !fixedHeight,
  };

  return (
    <AppContainer
      appLayout
      fixedHeight={fixedHeight}
      data-learning-state={learningState.data}
      bg={bgGradient}
    >
      <IfWearable mode={'striveware'}>
        <SwipeDetector sentinelRef={sentinelRef} />
      </IfWearable>
      <Box
        display={route.asPath.startsWith('/chat') ? 'none' : 'unset'}
        w={'full'}
      >
        {('pageTitle' in props ? false : props.banner === null ? true : false)
          ? null
          : bannerContent}
      </Box>

      <Box
        ref={contentRef}
        gridArea="content-body"
        maxWidth="100vw"
        isolation="isolate"
        position="relative"
        {...props.contentProps}
      >
        <Box
          ref={sentinelRef}
          position="absolute"
          top={0}
          left={0}
          right={0}
          height="1px"
          opacity={0}
          pointerEvents="none"
        />
        {childContent}
      </Box>

      {footer && cloneElement(footer, footerProps)}
    </AppContainer>
  );
}

type SwipeDetectorProps = {
  sentinelRef: React.RefObject<HTMLDivElement>;
};

function SwipeDetector({ sentinelRef }: SwipeDetectorProps) {
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const [isAtTop, setIsAtTop] = useState(true);
  const strivewareActor = StrivewareContext.useActorRef();
  const deviceState = StrivewareContext.useSelector(
    StrivewareSelector.deviceState,
  );

  const minSwipeDistance = 500;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]) {
          setIsAtTop(entries[0].isIntersecting);
        }
      },
      { threshold: 1.0 },
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => observer.disconnect();
  }, [sentinelRef]);

  useEffect(() => {
    const handleTouchStart = (e: globalThis.TouchEvent) => {
      // Only start tracking if we're at the top of the content
      if (isAtTop) {
        setTouchEnd(null);
        setTouchStart(e.touches[0]?.clientY ?? null);
      }
    };

    const handleTouchMove = (e: globalThis.TouchEvent) => {
      // Only track movement if we started at the top
      if (touchStart !== null) {
        setTouchEnd(e.touches[0]?.clientY ?? null);
      }
    };

    const handleTouchEnd = () => {
      if (!touchStart || !touchEnd) return;

      const distance = touchEnd - touchStart;

      // Only trigger if we're at the top, the swipe is long enough, and we're not already syncing
      if (isAtTop && distance > minSwipeDistance && deviceState !== 'syncing') {
        strivewareActor.send({ type: 'Sync' });
      }

      setTouchStart(null);

      setTouchEnd(null);
    };

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [
    touchStart,
    touchEnd,
    minSwipeDistance,
    isAtTop,
    strivewareActor,
    deviceState,
  ]);

  return null;
}
