import { useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Circle,
  Fade,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';

import { DataTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { AciContext, AciSelector, useACIGate } from '@arena-labs/strive2-data';
import {
  InfoIcon,
  PlusIcon,
  WaitForQueries,
  ZStack,
} from '@arena-labs/strive2-ui';
import { $API, Wearable } from '@strive/api';

import { useWearableHub } from '../wearable/use-wearable-hub';

export function AciCTACard() {
  const userQuery = $API.useGetUserProfile();
  const analytics = useAnalytics();
  const aciActor = AciContext.useActorRef();
  const baseline = $API.useGetProgressData();
  const isComplete = AciContext.useSelector(AciSelector.isComplete);
  const isSkipped = AciContext.useSelector(AciSelector.isSkipped);
  const acigate = useACIGate();
  const queryClient = useQueryClient();

  const [showNoDataModal, setShowNoDataModal] = useState(false);

  const skipAci = () => {
    analytics.logEvent(DataTrackingEvent.ACISkipped);
    aciActor.send({ type: 'skip' });
  };

  const openAci = () => {
    queryClient.removeQueries({
      queryKey: $API.getKeyByAlias('getPillars'),
    });
    queryClient.fetchQuery({
      queryKey: $API.getKeyByAlias('getPillars'),
      staleTime: 0,
      cacheTime: 0,
      networkMode: 'always',
    });
    aciActor.send({ type: 'open' });
    analytics.logEvent(DataTrackingEvent.ACIModalOpened);
  };

  const openAciInsights = (wearable?: Wearable) => {
    if (wearable?.provider) {
      aciActor.send({ type: 'open' });
    } else {
      acigate.actions.openToResults();
    }
    analytics.logEvent(DataTrackingEvent.ACIInsightsOpened);
  };

  return (
    <>
      <NoHRVDataModal
        isOpen={showNoDataModal}
        onClose={() => setShowNoDataModal(false)}
        onProceed={openAci}
      />
      <Fade in={userQuery.status === 'success'} style={{ display: 'contents' }}>
        <WaitForQueries queries={[userQuery, baseline]}>
          {(user, baseLine) => {
            const wearable = user.profile.wearable[0];
            const hasRecentHRVData = baseLine?.results.find((result) => {
              const today = format(new Date(), 'yyyy-MM-dd');
              return result.date === today && result.hrv != null;
            });
            const handleAciClick = () => {
              if (!hasRecentHRVData) {
                setShowNoDataModal(true);
              } else {
                openAci();
              }
            };

            return (
              <ZStack
                w={'full'}
                bg={'neutral.white'}
                rounded={'card'}
                position={'relative'}
                zIndex={0}
                border={'1px solid'}
                borderColor={'neutralAlpha.400'}
                overflow={'hidden'}
              >
                <Image
                  src="/images/check-in/surg.webp"
                  alt=""
                  position="absolute"
                  top={0}
                  left={0}
                  w="full"
                  h="full"
                  objectFit="cover"
                />
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  w="full"
                  h="full"
                  bg="neutral.white"
                  opacity={0.85}
                />

                <VStack
                  w="full"
                  px={6}
                  py={5}
                  gap={0}
                  textAlign={'left'}
                  position={'relative'}
                  color={'neutral.black'}
                >
                  {isSkipped ? (
                    <>
                      <Text textStyle={'h4'} color={'neutral.800'} mr={'auto'}>
                        Arena Check-In skipped
                      </Text>
                      <Text textStyle={'copy_small'} color={'neutral.600'}>
                        You decided to skip today. Come back tomorrow for a new
                        check-in!
                      </Text>
                    </>
                  ) : isComplete ? (
                    <>
                      <Text textStyle={'h4'} color={'neutral.800'} mr={'auto'}>
                        Arena Check-In completed
                      </Text>
                      <Button
                        variant={'underline-link'}
                        onClick={() => openAciInsights(wearable)}
                      >
                        <Text
                          textStyle={'copy_extra_small'}
                          color={'primary.500'}
                        >
                          View today&apos;s insights
                        </Text>
                      </Button>
                    </>
                  ) : (
                    <>
                      <HStack w={'full'}>
                        <Text
                          textStyle={'h4'}
                          color={'neutral.800'}
                          mr={'auto'}
                        >
                          Arena Check-In
                        </Text>
                        <Spacer />
                        <Circle
                          size={'30px'}
                          bg={'primary.800'}
                          onClick={handleAciClick}
                          cursor="pointer"
                        >
                          <Icon
                            as={PlusIcon}
                            boxSize={5}
                            color={'neutral.white'}
                          />
                        </Circle>
                      </HStack>
                      <Text
                        textStyle={'copy_extra_small'}
                        color={'neutral.600'}
                      >
                        Reflect on how you feel and see how it aligns with your
                        HRV data. Build self-awareness by checking in daily.
                      </Text>
                      <Button
                        variant={'link'}
                        onClick={() => skipAci()}
                        color={'primary.500'}
                        p={0}
                        height="auto"
                      >
                        <Text textStyle={'copy_extra_small'}>
                          {' '}
                          Skip and reveal data
                        </Text>
                      </Button>
                    </>
                  )}
                </VStack>
              </ZStack>
            );
          }}
        </WaitForQueries>
      </Fade>
    </>
  );
}

function NoHRVDataModal({
  isOpen,
  onClose,
  onProceed,
}: {
  isOpen: boolean;
  onClose: () => void;
  onProceed: () => void;
}) {
  const { onOpen } = useWearableHub();

  const handleDeviceClick = () => {
    onOpen();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="blackAlpha.200" />
      <ModalContent mx={4} bg="white">
        <ModalBody p={6} rounded={'card'} bg={'neutral.white'}>
          <VStack spacing={3} align="stretch">
            <HStack>
              <Spacer />
              <Icon as={InfoIcon} boxSize={6} color={'neutral.700'} />
              <Text textStyle="h4" color="neutral.800" w={'full'}>
                Missing HRV Data
              </Text>
              <Spacer />
            </HStack>

            <Text
              textStyle="copy_small"
              color="neutral.700"
              w={'full'}
              textAlign={'center'}
            >
              We have not recieved your overnight sleep data just yet. You can
              still check in, but it&apos;s best when we have your sleep data
              from last night.
            </Text>
            <ButtonGroup spacing={3} w="full" pt={2}>
              <Button
                variant="secondary"
                flex={1}
                onClick={() => handleDeviceClick()}
              >
                My Device
              </Button>
              <Button
                variant="primary"
                flex={1}
                onClick={() => {
                  onProceed();
                  onClose();
                }}
              >
                Proceed
              </Button>
            </ButtonGroup>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
